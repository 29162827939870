import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

export const companyGroupService = {
  all: (queryParams = null) => {
    return fetch(`/api/company_groups?${queryParams}`, request.get);
  },
  get: (company_group_id) => {
    return fetch(`/api/company_groups/${company_group_id}`, request.get);
  },
  update: (company_group) => {
    return fetch(`/api/company_groups/${company_group.id}`, {
      ...request.put,
      body: JSON.stringify({company_group})
    })
  },
  delete: (company_group_id) => {
    return fetch(`/api/company_groups/${company_group_id}`, request.delete);
  },
  create(company_group) { 
    return fetch(`/api/company_groups/`, {
      ...request.post,
      body: JSON.stringify({ company_group }),
    });
  },

  useDelete (companyGroupId, setCompanyGroups, setLoading) {
    const deleteCompanyGroup = useErrorHandling(useCallback(async () => {
      if (window.confirm('Are you sure you wish to delete this company?')) {
        setLoading(true)
        await this.delete(companyGroupId)
        const response = await this.all()
        setCompanyGroups(response.company_groups)
      }
    }, [companyGroupId, setCompanyGroups, setLoading]),
      () => setLoading(false)
    )

    return deleteCompanyGroup
  },
};
