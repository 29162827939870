import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

export const createRatesService = (endpoint, param_name) => {
  const endpointJson = `${endpoint}`
  return {
    all: async () => {
      try {
        return await fetch(endpointJson, request.get);
      } catch(e) {
        return Promise.reject(e);
      }
    },
    create: async (start_date, rates) => {
      try {
        return await fetch(endpointJson, {
          ...request.post,
          body: JSON.stringify({ [param_name]: { start_date, ...rates } })
        });
      } catch (e) {
        return Promise.reject(e);
      }
    },
    update: async (rate) => {
      const endpointWithId = `${endpoint}/${rate.id}`
      try {
        return await fetch(endpointWithId, {
          ...request.put,
          body: JSON.stringify({ [param_name]: rate })
        });
      } catch (e) {
        return Promise.reject(e);
      }
    },
    delete: async (rate) => {
      try {
        const endpointWithId = `${endpoint}/${rate.id}`
        return await fetch(endpointWithId, request.delete);
      } catch (e) {
        return Promise.reject(e);
      }
    }
  };
};
