import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import useCustomTemplateTitle from '@rd-web-markets/shared/dist/hooks/useCustomTemplateTitle';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const ReportTemplateList = ({claimGroup, reportTemplates, handleDelete, searchParams}) => {
  const user = useSelector((state) => state.auth.user);
	const [getTemplateTitle] = useCustomTemplateTitle();
  const { t } = useTranslation();
  const italySpecificTitle = t(getTemplateTitle(searchParams));

  const italySpecificSearchParams = searchParams?.replace('template_type', 'report_type') // '?report_type=TYPE'
  const editSearchParam = searchParams?.includes('technical') ? searchParams?.replace('technical', 'project_specific') : searchParams
  const urlParams = () => {
    if(searchParams && italySpecificSearchParams) {
      return (searchParams && italySpecificSearchParams)
    } else { 
      return ''
    }
  }

  return (
    <div>
      <Table hover>
        <thead>
          <tr>
          <th>{ t(italySpecificTitle) || 'R&D Report' }</th>
            <th></th>
            {claimGroup && <th></th>}
          </tr>
        </thead>
        <tbody>
          {reportTemplates.map((template) => {
            return (
              <tr key={template.id}>
                <td>{template.name}</td>
                <td className='text-right pr-4'>
                  {!searchParams?.includes('technical') && (
                    <>
                      <Button
                        as={Link}
                        to={
                          claimGroup
                            ? {
                                pathname: `/${user.account_type}/claim_groups/${claimGroup.id}/report_templates/${template.id}/edit`,
                                search: editSearchParam,
                              }
                            : { pathname: `/${user.account_type}/report_templates/${template.id}/edit`, search: searchParams }
                        }
                        variant='light'
                        className='p-1 d-inline-flex rounded-circle mr-1'
                      >
                        <span className='material-icons md-18 text-info rounded-circle mx-auto'>edit</span>
                      </Button>
                      <Button
                        isImmutableUnderReview={true}
                        variant='light'
                        className='p-1 d-inline-flex rounded-circle'
                        onClick={() => handleDelete(template, searchParams)}
                      >
                        <span className='material-icons md-18 text-warning rounded-circle mx-auto'>delete</span>
                      </Button>
                    </>
                  )}
                </td>
                {claimGroup && (
                  <td className='text-center'>
                    <Button
                      as={Link}
                      to={`/${user.account_type}/report_templates/${template.id}/rnd_reports${urlParams()}`}
                      size='md'
                      variant='info'
                    >
                      {t('view_and_generate_reports')}
                    </Button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ReportTemplateList;
