import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  FINANCIAL_SECTION_GENERAL_LINK,
  SIDEBAR_MENU_ITEMS,
  SIDEBAR_MENU_ITEMS_CUSTOMER,
  SHOULD_SHOW_ADD_CLAIM_BUTTON_IN_CLAIM_GROUP_SIDEBAR,
  SHOW_CLAIM_GROUPS_SELECTOR
} from '@rd-web-markets/market/dist/constants';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import claimProjectReportsListsService from '@services/lists/claim_groups/claim_project_reports_lists.service';
import getClaimGroupsService from '@rd-web-markets/shared/dist/services/claim_groups/getClaimGroups.service';
import ClaimGroupSelector from '@components/util/ClaimGroupSelector';

const ClaimGroupSidebar = ({
  claimGroup,
  highlightedMenuItem,
  showMenuItems = true,
  company,
  claims,
  accountType
}) => {
  const [loading, setLoading] = useState(false);
  const [projectsForSidebar, setProjectsForSidebar] = useState([])
  const { claim_group_step: claimGroupStep } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [claimGroups] = getClaimGroupsService.useFetchAllClaimGroups(company.id, setLoading);
  
  useEffect(() => {
    const fetchProjectsForSidebar = async () => {
      const allProjects = (await claimProjectReportsListsService.base.all(claimGroup.id, {})).claim_project_reports
      const sortedProjects = allProjects.sort((a, b) => a.report_order - b.report_order)

      const sortedProjectsForSidebar = sortedProjects.map(report => {
        return { text: report.project_name, link: `claim_groups/${claimGroup.id}/technical_proof/project_reports/${report.id}` }
      })

      setProjectsForSidebar([...sortedProjectsForSidebar])
    }

    if (claimGroup.projects_count <= 20) {
      fetchProjectsForSidebar()
    } else {
      setProjectsForSidebar([])
    }
  }, [claimGroup.id, claimGroup.projects_count])

  const getLink = (menuItem) => {
    if (menuItem.link === 'edit/general') {
      return `claim_groups/${claimGroup.id}/${menuItem.link}`
    } else if (menuItem.link === 'technical_section') {
      return `claim_groups/${claimGroup.id}/technical_proof/company_info`
    } else if (menuItem.link === 'project_overview') {
      return `claim_groups/${claimGroup.id}/project_overview`
    } else if (menuItem.link === 'dashboards' && user.account_type === 'customer') {
      const claimGroupYear = claimGroup.name?.slice(-4)
      return `dashboard/${claimGroup.company_id}?selected_year=${claimGroupYear}`
    } else if (menuItem.link === 'dashboard') {
      const company = claimGroup.company

      if(accountType === 'customer') {
        return company.dashboard_type === 'business_unit_intensity_breakdown' ?
          `claim_groups/${claimGroup.id}/regular_realtime_dashboard/${company.id}`
          :
          `claim_groups/${claimGroup.id}/dashboard/${company.id}`
      } else {
        return company.dashboard_type === 'business_unit_intensity_breakdown' ?
          `regular_realtime_dashboard/${company.id}`
          :
          `dashboard/${company.id}`
      }
    } else if (menuItem.link === 'data_centre') {
      return `claim_groups/${claimGroup.id}/${menuItem.link}/main_page`
      } else if (menuItem.link === 'technical_narratives') {
      return `claim_groups/${claimGroup.id}/${menuItem.link}`
    } else if (menuItem.link === 'claim_group_dashboard') {
      return `claim_groups/${claimGroup.id}/${menuItem.link}`
    } else {
      return `companies/${claimGroup.company_id}/${claimGroup.id}/${menuItem.link}`
    }
  }

  const getSubMenuItems = (menu) => {
    if (menu.link === 'claim_costs') {
      const subMenuItems = []

      claims.forEach(claim => {
        if (SHOW_CLAIM_GROUPS_SELECTOR && claim.claim_group_id === claimGroup.id) {
          subMenuItems.push({ text: claim.label, link: `claims/${claim.id}/financial_details/${FINANCIAL_SECTION_GENERAL_LINK}` })
        } else if (!SHOW_CLAIM_GROUPS_SELECTOR) {
          subMenuItems.push({ text: menu.showLastFourChars ? claim.name.slice(-4) : claim.name, link: `claims/${claim.id}/financial_details/${FINANCIAL_SECTION_GENERAL_LINK}` })
        }
      })

      if (SHOULD_SHOW_ADD_CLAIM_BUTTON_IN_CLAIM_GROUP_SIDEBAR) {
        subMenuItems.push({ text: 'Add Claim', link: `add_claim/company/${company.id_in_provider_system}/claim_groups/${claimGroup.id}` })
      }

      if (menu.showWages) {
        subMenuItems.unshift(
          { text: 'All Base', link: `companies/${claimGroup.company_id}/${claimGroup.id}/base/wages` },
          { text: 'All Claims', link: `companies/${claimGroup.company_id}/${claimGroup.id}/claims/wages` },
          { text: 'All Claims and Base', link: `companies/${claimGroup.company_id}/${claimGroup.id}/all/wages` }
        )
      }
      return subMenuItems
    } else if (menu.link === 'technical_section') {
      return projectsForSidebar
    } else {
      return menu.subMenu.map(subMenuItem => {
        return {
          text: subMenuItem.text,
          link: getLink(subMenuItem),
          highlighted: subMenuItem.link === highlightedMenuItem
        }
      })
    }
  }

  const getCustomerSideBarItems = (items) => {
    const ukCustomerDashboardAccess = user.user_accesses.find(uA => uA.rollable_id === claimGroup.id && uA.rollable_type === 'ClaimGroup')?.dashboard_access
    
    if (!ukCustomerDashboardAccess) {
      return items.filter(item => item.text !== 'dashboard')
    } else {
      return items;
    }
  }

  const items = user.account_type === 'customer' ? getCustomerSideBarItems(SIDEBAR_MENU_ITEMS_CUSTOMER) : SIDEBAR_MENU_ITEMS
  const headerItem = user.account_type === 'customer' ? { link: 'companies', text: claimGroup.name} : { link: `companies/${claimGroup.company_id}/master`, text: claimGroup.name }

  const menuItems = claimGroup && items.map(item => {
    let subMenuItems = item.subMenu ? getSubMenuItems(item) : false
    if (!subMenuItems.length) {
      subMenuItems = false
    }

    return {
      text: item.text,
      highlighted: item.link === highlightedMenuItem,
      link: getLink(item),
      icon: item.icon,
      isOpen: item.isOpen,
      subMenu: subMenuItems
    }
  })

  const claimCostItem = [{
    text: `Company Cost ${claimGroup.name.slice(-5)}`,
    highlighted: true,
    link: `companies/${claimGroup.company_id}/${claimGroup.id}/claim_costs`,
    subMenu: false
  }]

  return (
    <SidebarPortal
      menuItems={(claimGroupStep === 'claim_costs' && !showMenuItems) ? claimCostItem : menuItems}
      headerItem={
        // Add this before we release new claim group master page for other markets
        !SHOW_CLAIM_GROUPS_SELECTOR ? headerItem : { link: '', text: 'home' }
      }
      dividerPosition={1}
      showMenuItems={showMenuItems}
      children={
        // Add this before we release new claim group master page for other markets
        SHOW_CLAIM_GROUPS_SELECTOR && accountType !== 'customer' ?
          <ClaimGroupSelector
            accountType={accountType}
            loading={loading}
            company={company}
            claimGroup={claimGroup}
            allClaimGroups={claimGroups}
          />
          :
          null
      }
    />
  )
}

export default ClaimGroupSidebar
