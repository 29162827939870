import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const technicalUploadsService = {
  all: (claimGroupId) => {
    return fetch(`/api/claim_groups/${claimGroupId}/technical_uploads`, request.get);
  },
  create(claimGroupId, formData) {
    return fetch(`/api/claim_groups/${claimGroupId}/technical_uploads/`, {
      ...request.postFormData,
      body: formData
    });
  },
  delete(claimGroupId, uploadId) {
    return fetch(`/api/claim_groups/${claimGroupId}/technical_uploads/${uploadId}`, request.delete);
  },
}

export default technicalUploadsService;
