import { useCallback } from 'react';
import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { buildQueryString, buildService } from '@rd-web-markets/shared/dist/services/service'
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods'

const api = buildService('/my/claims', { only: [ 'all']})

const myClaimsService = {
  useFetchAllClaims(setLoading, initialQueryParams) {
    const [claims, setClaims, fetchClaims] = serviceMethods.useFetch({
      callback: useCallback(async (queryParams) => {
        const response = await api.all({ query: buildQueryString(queryParams) })
        return response.claims
      }, []),
      setLoading,
      initialQueryParams: initialQueryParams || ''
    })
  
    return [claims, setClaims, fetchClaims]
  },
  all(params) {
    return fetch(`/api/my/claims?${params}`, request.get);
  },
}

export default myClaimsService;
