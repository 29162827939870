import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const claimChangeSetsService = {
  /**
   * Creates a change set for all claims under the claim group
   * 
   * @param {Number} claimGroupId 
   * @returns 
   */
  create(claimGroupId) { 
    return fetch(`/api/claim_groups/${claimGroupId}/claim_change_sets/`, request.post);
  },
}

export default claimChangeSetsService;
