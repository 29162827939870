import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const claimScheduleIcsEventTemplateService = {
  delete(claimScheduleId) { 
    return fetch(`/api/claim_schedules/${claimScheduleId}/claim_schedule_ics_event_template`, request.delete);
  },
  update(claimScheduleId, claim_schedule_ics_event_template) {
    return fetch(`/api/claim_schedules/${claimScheduleId}/claim_schedule_ics_event_template`, {
      ...request.put,
      body: JSON.stringify({ claim_schedule_ics_event_template })
    });
  },
  create(claimId, claim_schedule_ics_event_template) { 
    return fetch(`/api/claim_schedules/${claimId}/claim_schedule_ics_event_template/`, {
      ...request.post,
      body: JSON.stringify({ claim_schedule_ics_event_template }),
    });
  }
}

export default claimScheduleIcsEventTemplateService;