import React, { useEffect, useState, useCallback } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import ClaimTemplateImportStatus from '@components/admin/claimTemplate/ClaimTemplateImportStatus';
import Fetcher from '@rd-web-markets/shared/dist/services/Fetcher';
import { useHistory } from 'react-router-dom';
import { TEMPLATE_PAGE_TITLE } from '@rd-web-markets/market/dist/constants';
import { useTranslation } from 'react-i18next';
import { makeKey } from '@rd-web-markets/shared/dist/hooks/useConvertForTranslationKey';
import MasterSidebar from '@components/util/MasterSidebar';
import { ClaimTemplateList } from '@rd-web-markets/market/dist/claim_group/claimTemplate';

const ClaimTemplate = ({ accountType }) => {
  const [claimTemplateTasks, setClaimTemplateTasks] = useState([]);
  const [claimTemplateImportStatus, setClaimTemplateImportStatus] = useState(undefined);
  const [isPending, setIsPending] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const fetchTemplateTasks = useCallback(async () => {
    try {
      const response = await Fetcher('/api/claim_template_tasks');
      setClaimTemplateTasks(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchTemplateImport = useCallback(async () => {
    try {
      const response = await Fetcher(
        '/api/claim_template_imports'
      );
      setClaimTemplateImportStatus(response);
      if(response.import_status === 'pending' && isPending === true) {
        setIsPending(true);
      }
      else {
        setIsPending(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [isPending]);

  useEffect(() => {
    fetchTemplateTasks();
  }, [fetchTemplateTasks, claimTemplateImportStatus]);

  useEffect(() => {
    fetchTemplateImport();
  }, [fetchTemplateImport]);

  useEffect(() => {
    if(isPending) {
      const intervalId = window.setInterval(() => {
        fetchTemplateImport();
      }, 3000);
      return () => window.clearInterval(intervalId);
    }
  }, [fetchTemplateImport, isPending] )

  const upload = event => {
    let fd = new FormData();
    fd.append('file', event.target.files[0]);
    fetch('/api/claim_template_imports/', {
      method: 'POST',
      body: fd,
    })
      .then((response) => response.json())
      .then(() => {
        setClaimTemplateImportStatus({ ...claimTemplateImportStatus, import_status: 'pending'});
        setIsPending(true);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

  };

  return (
    <>
      <h2 className="text-primary mb-4 mt-4">{t(makeKey(TEMPLATE_PAGE_TITLE))}</h2>
      <MasterSidebar accountType={accountType} currentPage='claim_template' />
        <Card>
          <Card.Body>
            <ClaimTemplateList claimTemplateTasks={claimTemplateTasks} />
            <Col md={12}>
            { claimTemplateImportStatus && <ClaimTemplateImportStatus import_status={claimTemplateImportStatus.import_status} importMessage={claimTemplateImportStatus.import_messages}/> }
              <label className="btn btn-info btn-md mr-2">
                {t('upload_from_excel')}
                <input style={{display:'none'}} type="file" onChange={upload}></input>
              </label>
              { claimTemplateImportStatus &&
                <a href={claimTemplateImportStatus.download_link.url} target="_blank" rel="noreferrer">
                  <label className="btn btn-info btn-md">
                    {t('download')}
                  </label>
                </a> }
            </Col>
          </Card.Body>
        </Card>
    </>
  );
}

export default ClaimTemplate
