import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const personnelFrameworksService = {
  all(reportId) {
    return fetch(`/api/claim_project_reports/${reportId}/personnel_frameworks`, request.get);
  },
  update(reportId, personnel_framework) { 
    return fetch(`/api/claim_project_reports/${reportId}/personnel_frameworks/${personnel_framework.id}`, {
      ...request.put,
      body: JSON.stringify({ personnel_framework }),
    });
  }
}

export default personnelFrameworksService;
