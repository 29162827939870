import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const claimGroupImportTechnicalTemplatesService = {
  create(claimGroupId, formData) {
    return fetch(`/api/claim_groups/${claimGroupId}/claim_import_technical_templates/`, {
      ...request.post,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      },
      body: formData
    });
  },
}

export default claimGroupImportTechnicalTemplatesService;
