import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
export const claimProjectOverviewNoteService = {
  create: (claimProjectReportId, body) => {
    return fetch("/api/claim_project_reports/".concat(claimProjectReportId, "/report_notes"), {
      ...request.post,
      body: JSON.stringify({
        ...body
      })
    });
  },
  delete: (claimProjectReportId, noteId) => {
    return fetch("/api/claim_project_reports/".concat(claimProjectReportId, "/report_notes/").concat(noteId), request.delete);
  }
};