import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const localLanguagesService = {
  update(local_language) {
    return fetch('/api/local_languages/', {
      ...request.put,
      body: JSON.stringify({ local_language }),
    });
  },

}

export default localLanguagesService;
