import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

export const claimProjectReportsService = {
  all: (claimGroupId, queryParams = null) => {
    return fetch(`/api/time_tracking/claim_groups/${claimGroupId}/claim_project_reports?${queryParams}`, request.get);
  },
  get: (claimGroupId, id) => {
    return fetch(`/api/time_tracking/claim_groups/${claimGroupId}/claim_project_reports/${id}`, request.get);
  }
};
