import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const exportCostTemplateService = {
  get(claimId) {
    window.open(`/api/claims/${claimId}/claim_export_cost_template`)
  }
}

export default exportCostTemplateService;
