import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const claimGroupContractorsService = {
  all(claimGroupId, type) { 
    return fetch(`/api/claim_groups/${claimGroupId}/claim_contractors?type=${type}`, request.get);
  }
}

export default claimGroupContractorsService;
