import { useCallback, useState, useEffect } from 'react';
import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { useDispatch, useSelector } from 'react-redux';
import { storeCompanies } from '@rd-web-markets/shared/dist/store/features/companiesSlice';

export const CompanyService = {
  useGetAll (queryStringParams, setLoading) {
    const companies = useSelector((state) => state.companies.companies);
    const dispatch = useDispatch()

    const fetchCompanies = useErrorHandling(
      useCallback(
        async () => {
          setLoading(true)
          const response = await CompanyService.all(queryStringParams)
          dispatch(storeCompanies(response))
        },
        [dispatch, queryStringParams, setLoading]
      ), useCallback(async () => {
        setLoading(false)
      }, [setLoading]))

    useEffect(() => {
      fetchCompanies()
    }, [fetchCompanies])

    return [companies.companies, companies.pages, fetchCompanies]
  },
  useFetchCompaniesForSelect: () => {
    const [companies, setCompanies] = useState([]);

    const fetchCompanies = useErrorHandling(useCallback(async () => {
      const response = await CompanyService.all();
      setCompanies(response.companies.map(company => ({ value: company.id, label: company.name })));
    }, []));

    useEffect(() => {
      fetchCompanies();
    }, [fetchCompanies]);

    return [companies, setCompanies];
  },
  all: (queryParams = null) => {
    return fetch(`/api/companies?${queryParams}`, request.get);
  },
  get: (company_id) => {
    return fetch(`/api/companies/${company_id}`, request.get);
  },
  update: (company) => {
    return fetch(`/api/companies/${company.id}`, {
      ...request.put,
      body: JSON.stringify({company})
    })
  },
  delete: (company_id) => {
    return fetch(`/api/companies/${company_id}`, request.delete);
  }
};
