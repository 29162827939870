const useCustomTemplateTitle = () => {
  const getTemplateTitle = searchParams => {
    // We need to use && instead of ?. because the babel preset env does not load the optional chaining module for some reason.
    // TODO: to fix later.
    if (searchParams && searchParams.includes('inexpert_declaration_dossier')) {
      return 'self_declaration_dossier_report';
    } else if (searchParams && searchParams.includes('expert_declaration_dossier')) {
      return 'technical_expert_dossier_report';
    } else if (searchParams && searchParams.includes('technical')) {
      return 'technical_analysis_report';
    } else if (searchParams && searchParams.includes('project_specific')) {
      return 'technical_analysis_report';
    } else if (searchParams && searchParams.includes('rnd')) {
      return 'rnd_report';
    } else if (searchParams && searchParams.includes('aif')) {
      return 'AIF_report';
    }
  };
  return [getTemplateTitle];
};
export default useCustomTemplateTitle;