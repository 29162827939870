import React, { useCallback, useState } from 'react'
import { useEffect } from 'react';
import { Breadcrumb, Button, Card, Col, Nav, Row, Pagination } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
import UserList from '../../components/users/UserList';
import * as QueryString from 'query-string';
import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { UserService } from '@rd-web-markets/shared/dist/services/user.service';
import SearchBar from '@components/util/SearchBar';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import MasterSidebar from '@components/util/MasterSidebar';
import { useTranslation } from 'react-i18next';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';

let initialParams = 'type=customer&page=1&query=';
let initialUser = {company_users_attributes: [], company_users: []}

const UserPage = ({ accountType }) => {
  const [users, setUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState({...initialUser});
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const history = useHistory();
  const { t } = useTranslation();
  const [params, setParams] = useState(QueryString.parse(window.location.search || initialParams));

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you wish to delete this user?')) {
      try {
        await UserService.delete(id);
        const response = await fetch(`/api/users?${QueryString.stringify(params)}`, {
          method: 'GET',
        });
        setUsers(response.users);
      } catch(error) {
        dispatch(handleError(error));
      }
    }
  }

  const resetUsersAndPages = async () => {
    setSelectedUser({company_users_attributes: [], company_users: []})
    const response = await fetch(`/api/users?${QueryString.stringify(params)}`, {
      method: 'GET',
    });
    setUsers(response.users);
    setTotalPages(response.pages);
  }

  const handleCreateUpdate = async () => {
    let method, url;
    if (selectedUser.id) {
      method = 'PUT';
      url = `/api/users/${selectedUser.id}`;
    } else {
      method = 'POST';
      url = '/api/users/';
    }
    await fetch(url, {
      method: method,
      body: JSON.stringify({ user: selectedUser }),
    });

    await resetUsersAndPages();
  };

  useEffect(() => {
    setLoading(true);
    fetch(`/api/users?${QueryString.stringify(params)}`,
      {
        method: 'GET',
      }
    )
      .then((response) => {
        setUsers(response.users);
        setTotalPages(response.pages);
        history.push({
          path: 'admin/users',
          search: QueryString.stringify(params),
        });
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, [history, params]);

  const handleActivate = useErrorHandling(useCallback(async (e, userId) => {
    setLoading(true);
    const user = users.find(user => user.id === userId);
    user['is_active'] = e.target.checked
    await UserService.update(user);
    setLoading(false);
  }, [users]));

  const handleSelect = (eventKey) => {
    setParams({ ...params, type: eventKey });
  };

  const handleSearch = (query) => {
    setParams({ ...params, query, page: 1 });
  };

  const handlePage = (pageNumber) => {
    setParams({...params, page: pageNumber})
  }
  const tabs = [
    { eventKey: 'customer', title: t('clients') },
    { eventKey: process.env.PUBLIC_URL === '/de' ? 'super_consultant' : 'consultant', title: t('consultants') },
    { eventKey: 'admin', title: t('admins') },
  ];

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === parseInt(params.page)}
        onClick={() => handlePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='users' />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: '/admin'}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('users')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <Card>
        <Card.Header>
          <Nav
            variant="tabs"
            defaultActiveKey={params.type}
            onSelect={handleSelect}
          >
            {tabs.map((tab) => {
              return (
                <Nav.Item key={tab.eventKey}>
                  <Nav.Link eventKey={tab.eventKey}>{tab.title}</Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
        </Card.Header>
        <Card.Body>
          <Row className="mb-3">
            <Col md={4}>
              <Button variant="primary" size="md" as={Link} to={'/admin/users/new'}>
                <span className="material-icons md-18">add</span> {t('invite_users')}
              </Button>
            </Col>
            <Col md={{ span: 4, offset: 4 }}>
              <SearchBar onSubmit={handleSearch} loading={loading} query={params.query} />
            </Col>
          </Row>
          {users && (
            <UserList
              users={users}
              handleActivate={handleActivate}
              usersType={params.type}
              handleDelete={handleDelete}
              handleCreateUpdate={handleCreateUpdate}
              onUsersChange={resetUsersAndPages}
              params={params}
              setParams={setParams}
            />
          )}
        </Card.Body>
      </Card>
      <Pagination className="justify-content-end">{items}</Pagination>
    </>
  );
};
export default UserPage;
