import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const claimGroupReportTemplateService = {
  delete(claimGroupId, templateId, deleteSpecificParam) {
    return fetch("/api/claim_groups/".concat(claimGroupId, "/report_templates/").concat(templateId).concat(deleteSpecificParam), request.delete);
  },
  update(claimGroupId, report_template, templateType) {
    let archived = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    report_template = templateType ? {
      ...report_template,
      template_type: templateType
    } : report_template; // Italy
    report_template = archived ? {
      ...report_template,
      archived: archived
    } : report_template;
    return fetch("/api/claim_groups/".concat(claimGroupId, "/report_templates/").concat(report_template.id), {
      ...request.put,
      body: JSON.stringify({
        report_template
      })
    });
  },
  create(claimGroupId, report_template) {
    return fetch("/api/claim_groups/".concat(claimGroupId, "/report_templates/"), {
      ...request.post,
      body: JSON.stringify({
        report_template
      })
    });
  }
};
export default claimGroupReportTemplateService;