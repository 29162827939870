import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const technicalUploadLinkService = {
  update(technicalUploadId, linkId, formData) {
    return fetch(`/api/technical_uploads/${technicalUploadId}/one_time_link_uploads/${linkId}`, {
      ...request.putFormData,
      body: formData,
    });
  },
  get(technicalUploadId, linkId) {
    return fetch(
      `/api/technical_uploads/${technicalUploadId}/one_time_link_uploads/${linkId}`, {...request.get }
    );
  }
}

export default technicalUploadLinkService;
