import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Breadcrumb } from 'react-bootstrap';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { Link, useLocation } from 'react-router-dom';
import reportTemplateService from '@services/report_template.service';
import ReportTemplateList from '@components/admin/reportTemplates/ReportTemplateList';
import useCustomTemplateTitle from '@rd-web-markets/shared/dist/hooks/useCustomTemplateTitle';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import MasterSidebar from '@components/util/MasterSidebar';
import { useTranslation } from 'react-i18next';
import { SHOULD_ADD_REPORT_TEMPLATE_SEARCH, SHOULD_SHOW_TEMPLATE_TITLE } from '@rd-web-markets/market/dist/constants';

const ReportTemplatePage = ({ accountType }) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
	const [getTemplateTitle] = useCustomTemplateTitle();
  const { t } = useTranslation();
  const { search } = useLocation() //Required for Italy

  const italyTitle = t(getTemplateTitle(search));
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await reportTemplateService.all(search);
        setTemplates(response);
      } catch (error) {
        dispatch(handleError(error));
      }
    };
    fetchData();
  }, [dispatch, search]);

  const handleDelete = async targetTemplate => {
    if(window.confirm('Are you sure you want to delete this template?')){
      setLoading(true);
      try {
        await reportTemplateService.delete(targetTemplate.id);
        setTemplates([...templates.filter(template => template.id !== targetTemplate.id)])
      } catch(error) {
        dispatch(handleError(error))
      }
    }
  }

  if (!templates) return <Loading />;

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage={ SHOULD_ADD_REPORT_TEMPLATE_SEARCH ? `report_templates${search}` : 'report_templates'} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: '/admin'}} active>{t('home')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      
      <Row>
        <Col md={12}>
          <Col>
            <h1 className="text-primary mb-4 mt-2">{SHOULD_SHOW_TEMPLATE_TITLE ? italyTitle : 'Report Templates'}</h1>
          </Col>
          <Row>
            <Col md={{ span: 2, offset: 10 }}>
              <Button
                variant="primary"
                as={Link}
                to={{ pathname: '/admin/report_templates/create', search: search }}>
                <span className="material-icons md-18">add</span> {SHOULD_SHOW_TEMPLATE_TITLE ? `Add ${italyTitle}` : 'Add Report Templates'}
              </Button>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col >
              {templates.length > 0 && <ReportTemplateList loading={loading} reportTemplates={templates} handleDelete={handleDelete} searchParams={search} />}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default ReportTemplatePage
