import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const financialFrameworksService = {
  all(reportId) {
    return fetch(`/api/claim_project_reports/${reportId}/financial_frameworks`, request.get);
  },
  update(reportId, financial_framework) { 
    return fetch(`/api/claim_project_reports/${reportId}/financial_frameworks/${financial_framework.id}`, {
      ...request.put,
      body: JSON.stringify({ financial_framework }),
    });
  }
}

export default financialFrameworksService;
