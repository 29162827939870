import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const kickoffEmailTEmplateService = {
  all() {
    return fetch('/api/email_templates', request.get);
  },
  create(email_template) {
    return fetch('/api/email_templates/', {
      ...request.post,
      body: JSON.stringify({ email_template }),
    });
  },
  update(id, email_template) {
    return fetch(`/api/email_templates/${id}`, {
      ...request.put,
      body: JSON.stringify({ email_template }),
    });
  },
  delete(id) {
    return fetch(`/api/email_templates/${id}`, {
      ...request.delete
    });
  }
};

export default kickoffEmailTEmplateService;