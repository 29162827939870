import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const affiliatedCompaniesService = {
  create(claimGroupId, affiliated_company) { 
    return fetch(`/api/claim_groups/${claimGroupId}/affiliated_companies/`, {
      ...request.post,
      body: JSON.stringify({ affiliated_company }),
    });
  },
  update(claimGroupId, affiliated_company) { 
    return fetch(`/api/claim_groups/${claimGroupId}/affiliated_companies/${affiliated_company.id}`, {
      ...request.put,
      body: JSON.stringify({ affiliated_company }),
    });
  },
  all(claimGroupId) { 
    return fetch(`/api/claim_groups/${claimGroupId}/affiliated_companies/`, {
      ...request.get,
    });
  },
  delete(claimGroupId, affiliated_company_id) {
    return fetch(`/api/claim_groups/${claimGroupId}/affiliated_companies/${affiliated_company_id}`, {
      ...request.delete,
    });
  }
}

export default affiliatedCompaniesService;