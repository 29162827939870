import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

export const ClaimSubmissionService = {
  create (claim_id, claim_submission) {
    return fetch(`/api/claims/${claim_id}/claim_submissions`, {
      ...request.post,
      body: JSON.stringify({ claim_submission })
    });
  }
};