import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const emailAttachmentsService = {
  create(route, id, formData) {
    return fetch(`/api/${route}/${id}/template_attachments/`, {
      ...request.post,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      },
      body: formData
    });
  },
  delete(route, templateId, id) { 
    return fetch(`/api/${route}/${templateId}/template_attachments/${id}`, {
      ...request.delete,
    });
  }
}

export default emailAttachmentsService;