import { TableOfContents } from 'docx';
import { grayTitlesMainNotBold, mainParagraphOptions } from '../styles/elsterDocStyles';
import i18n from 'i18next';
import { elsterDocxUtils } from '../utils/elster_docx_utils';
const tableOfContent2 = () => {
  const {
    t
  } = i18n;
  const {
    createTextRunInParagraph
  } = elsterDocxUtils;
  const tableOfContent = new TableOfContents('Table of Content', {
    hyperlink: true,
    headingStyleRange: '1-5'
  });
  return [createTextRunInParagraph(t('content'), grayTitlesMainNotBold, mainParagraphOptions), tableOfContent];
};
export default tableOfContent2;