import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const reportTemplateCategoryService = {
  get(reportTemplateId, reportTemplateCategoryId) { 
    return fetch(`/api/report_templates/${reportTemplateId}/report_template_categories/${reportTemplateCategoryId}`, request.get);
  },

  update(reportTemplateId, report_template_category) { 
    return fetch(
      `/api/report_templates/${reportTemplateId}/report_template_categories/${report_template_category.id}`,
      {
        ...request.put,
        body: JSON.stringify({ report_template_category })
      }
    );
  }
};

export default reportTemplateCategoryService;