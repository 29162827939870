import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useDispatch } from 'react-redux';
import { useErrorHandling } from '../../hooks/useErrorHandling';
import { useCallback, useEffect, useState } from 'react';
import { setIsUnderTechnicalReview } from '../../store/features/reviewSlice';
function get(id) {
  return fetch("/api/claims/".concat(id), request.get);
}
function update(claim) {
  claim.internal_invoice_detail_attributes = claim.internal_invoice_detail_attributes || claim.internal_invoice_detail;
  return fetch("/api/claims/".concat(claim.id), {
    ...request.put,
    body: JSON.stringify({
      claim
    })
  });
}
;
function create(claim) {
  return fetch('/api/claims/', {
    ...request.post,
    body: JSON.stringify({
      claim
    })
  });
}
function updateInvoiced(claim, invoiceDetails) {
  claim.internal_invoice_detail || (claim.internal_invoice_detail = {});
  claim.internal_invoice_detail.final_benefit_amount = invoiceDetails.final_benefit_amount;
  claim.internal_invoice_detail.invoice_amount = invoiceDetails.invoice_amount;
  return update(claim);
}
;
function uploadTechnicalDocument(claimId, fd) {
  return fetch("/api/claims/".concat(claimId, "/upload_technical_document"), {
    ...request.post,
    headers: {
      'Accept': 'application/json, text/plain, */*'
    },
    body: fd
  });
}
;
function deleteTechnicalDocument(claimId, document_id) {
  return fetch("/api/claims/".concat(claimId, "/delete_technical_document"), {
    ...request.post,
    body: JSON.stringify({
      document_id
    })
  });
}
;
function deleteClaim(id) {
  return fetch("/api/claims/".concat(id), {
    ...request.delete
  });
}
const claimService = {
  // Refactoring to set the isUnderReview state on claim fetch.
  // To replace get when the refactoring is done.
  useGetClaim(claimId) {
    let initialState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    const [claim, setClaim] = useState(initialState);
    const dispatch = useDispatch();
    const fetchAndUpdateTechnicalReviewState = useErrorHandling(useCallback(async claimId => {
      if (claimId) {
        const fetchedClaim = await claimService.get(claimId);
        setClaim(fetchedClaim);
        dispatch(setIsUnderTechnicalReview(!!fetchedClaim.claim_group.active_change_set));
      }
    }, []));
    useEffect(() => {
      fetchAndUpdateTechnicalReviewState(claimId);
    }, [fetchAndUpdateTechnicalReviewState, claimId]);
    return [claim, setClaim];
  },
  get,
  update,
  updateInvoiced,
  uploadTechnicalDocument,
  deleteTechnicalDocument,
  deleteClaim,
  create
};
export default claimService;