import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const claimScheduleService = {
  get(claimGroupId, id) {
    return fetch("/api/claim_groups/".concat(claimGroupId, "/claim_schedules/").concat(id), request.get);
  },
  sendIntroductionMail(scheduleId) {
    return fetch("/api/claim_schedules/".concat(scheduleId, "/claim_schedule_kickoff_mailer"), {
      ...request.post
    });
  },
  sendIcsEventMail(scheduleId) {
    return fetch("/api/claim_schedules/".concat(scheduleId, "/claim_schedule_ics_mailer"), {
      ...request.post
    });
  },
  update(claimGroupId, claim_schedule) {
    return fetch("/api/claim_groups/".concat(claimGroupId, "/claim_schedules/").concat(claim_schedule.id), {
      ...request.put,
      body: JSON.stringify({
        claim_schedule
      })
    });
  },
  updateEmailManagerIncluded(claimGroupId, claim_schedule, manager_included, is_cost_email) {
    if (is_cost_email) {
      claim_schedule.exclude_manager_from_cost_template_email = manager_included;
    } else {
      claim_schedule.exclude_manager_from_introduction_email = manager_included;
    }
    return this.update(claimGroupId, claim_schedule);
  },
  sendClientCostMail(scheduleId) {
    return fetch("/api/claim_schedules/".concat(scheduleId, "/client_cost_template_mailer"), {
      ...request.post
    });
  }
};
export default claimScheduleService;