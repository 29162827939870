import { useCallback, useState, useEffect } from 'react';
import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';

const claimGroupQuestionnairesService = {
  useQuestionnaires(claimGroupId) {
    const [questionnaires, setQuestionnaires] = useState(null);

    const fetchQuestionnaires = useErrorHandling(useCallback(async () => {
      const questionnaires = await claimGroupQuestionnairesService.all(claimGroupId);
      setQuestionnaires(questionnaires);
    }, [claimGroupId]));

    useEffect(() => {
      fetchQuestionnaires();
    }, [fetchQuestionnaires]);

    return [questionnaires, setQuestionnaires];
  },
  useQuestionnaire(claimGroupId, id) {
    const [questionnaire, setQuestionnaire] = useState(null);

    const fetchQuestionnaire = useErrorHandling(useCallback(async () => {
      const questionnaire = await claimGroupQuestionnairesService.get(claimGroupId, id);
      setQuestionnaire(questionnaire);
    }, [claimGroupId, id]));

    useEffect(() => {
      fetchQuestionnaire();
    }, [fetchQuestionnaire]);

    return [questionnaire, setQuestionnaire];
  },
  useSaveQuestionnaire(claimGroupId, questionnaire) {
    return useErrorHandling(useCallback(async () => {
      if (questionnaire.id) {
        questionnaire.claim_group_questionnaire_answers_attributes = questionnaire.claim_group_questionnaire_answers;
        delete questionnaire.claim_group_questionnaire_answers;
        await claimGroupQuestionnairesService.update(claimGroupId, questionnaire.id, questionnaire);
      } else {
        await claimGroupQuestionnairesService.create(claimGroupId, questionnaire);
      }
    }, [claimGroupId, questionnaire]));
  },
  all(claimGroupId) {
    return fetch(`/api/claim_groups/${claimGroupId}/questionnaires`, request.get);
  },
  get(claimGroupId, id) {
    return fetch(`/api/claim_groups/${claimGroupId}/questionnaires/${id}`, request.get);
  },
  create(claimGroupId, questionnaire) { 
    return fetch(`/api/claim_groups/${claimGroupId}/questionnaires`, {
      ...request.post,
      body: JSON.stringify({ questionnaire }),
    });
  },
  delete(claimGroupId, id) {
    return fetch(`/api/claim_groups/${claimGroupId}/questionnaires/${id}`, request.delete);
  },
  update(claimGroupId, id, questionnaire) {
    return fetch(`/api/claim_groups/${claimGroupId}/questionnaires/${id}`, {
      ...request.put,
      body: JSON.stringify({ questionnaire }),
    });
  }
}

export default claimGroupQuestionnairesService;
