import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@rd-web-markets/shared/dist/util';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Col, Row, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { AddButton, SaveButton, Button, DeleteButton } from '@rd-web-markets/shared/dist/util/buttons';
import ImmutableUnderReviewFormControl from '@rd-web-markets/shared/dist/util/reviewEditControls/ImmutableUnderReviewFormControl';
import ProjectReportDateColumns from '@rd-web-markets/shared/dist/util/date/ProjectReportDateColumns';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import { PROJECT_REPORT_DATE_PICKER_TYPE } from '@rd-web-markets/market/dist/constants';
import { TEMPLATE_SELECT_MODEL_NAME } from '@rd-web-markets/market/dist/constants';
import { PROJECT_FIELD_MODEL_NAME } from '@rd-web-markets/market/dist/constants';
import { SHOW_PROJECT_TEMPLATE_SELECT } from '@rd-web-markets/market/dist/constants';
import TemplateCateogiresForCreatingProject from '@rd-web-markets/shared/dist/util/helpers/template_categories_for_createing_project';
import { IS_PROJECT_DATE_SHORT_FORMAT } from '@rd-web-markets/market/dist/constants';
import { PROJECT_OVERVIEW_TABLE } from '@rd-web-markets/market/dist/constants';
import SetUpQuestionnaireModal from './SetUpQuestionnaireModal';
import CostFormatter from '@rd-web-markets/market/dist/claim_project_reports/claim_project_report_list/CostFormatter';
import { useGetUserClaimGroupAbilities } from '@rd-web-markets/market/dist/userAbilities';
import { ChevronDownButton } from '@rd-web-markets/shared/dist/util/buttons/ChevronDownButton';
import { PROJECT_GROUP_RPOGRAM_TYPE_NAME } from '@rd-web-markets/market/dist/constants';
import { ChevronUpButton } from '@rd-web-markets/shared/dist/util/buttons/ChevronUpButton';
import { SHOULD_SHOW_CREATE_PROJECTS_PAGE_FOR_CUSTOMER } from '@rd-web-markets/market/dist/constants';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import claimProjectReportService from '@services/claim_project_report.service';
import { SHOW_SEND_QUESTIONNAIRE_BUTTON_ON_PROJECT_OVERVIEW_PAGE } from '@rd-web-markets/market/dist/constants';
import { SHOW_IMPORT_PROJECT_AT_PROJECT_OVERVIEW } from '@rd-web-markets/market/dist/constants';
import ProjectImportButtonAndModal from './ProjectImportButtonAndModal';
import projectImportService from '@rd-web-markets/shared/dist/services/claim_project_overview/project_import.service';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import StaffCostTemplateImportStatus from '@rd-web-markets/shared/dist/claim_group/StaffCostTemplateImportStatus';
import { SHOW_IT_TYPE_SELECT } from '@rd-web-markets/market/dist/constants';

const ClaimProjectReportsList = ({ reports, claimGroup, setClaimGroup, handleToaster, params, setParams, getData, groupProjects = false }) => {
  const { t } = useTranslation();
  
  const user = useSelector((state) => state.auth.user);
  const userClaimGroupAbilities = useGetUserClaimGroupAbilities(user, claimGroup.id)
  const pageAccess = userClaimGroupAbilities?.pages?.ProjectOverviewPage
  const dispatch = useDispatch();
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [claimProjectReports, setClaimProjectReports] = useState(reports);
  const [newReport, setNewReport] = useState({});
  const updateClaimGroupFunction = claimGroupService.useUpdateClaimGroup(claimGroup.id, setClaimGroup);
  const datePickerType = PROJECT_REPORT_DATE_PICKER_TYPE
  const [modalShow, setModalShow] = useState(false);
  const { claimGroupId } = useParams();
  const { importStatus, importProjects } = projectImportService.useCrud(claimGroupId, getData);
  const [shouldRemoveSubsidiaryName, setShouldRemoveSubsidiaryName] = useState(false)

  useEffect(() => {
    setShouldRemoveSubsidiaryName(!claimProjectReports[0]?.subsidiary_company?.has_subsidiary_companies)
  }, [claimProjectReports])
  
  
  useEffect(() => {
    const defaultProjectAttributes = {
      project_name: '',
      start_date: IS_PROJECT_DATE_SHORT_FORMAT ? claimGroup?.from_date?.slice(3) : claimGroup.from_date,
      end_date: IS_PROJECT_DATE_SHORT_FORMAT ? claimGroup?.to_date?.slice(3) : claimGroup.to_date,
    };
    setNewReport(defaultProjectAttributes);
    setClaimProjectReports(reports);
  }, [showProjectForm, reports, claimGroup.from_date, claimGroup.to_date]);

  useEffect(() => {
    setClaimProjectReports(reports);
  }, [reports, claimGroup]);

  const createHeaders = () => {
    const headers = [...PROJECT_OVERVIEW_TABLE.headers]
    if (groupProjects === 'program') {
      headers.push({ text: t('view_projects') })
    }

    
    if (shouldRemoveSubsidiaryName){
      return headers.filter(h => h.text !== 'subsidiary_name')
                    .map(h => {
                      return { ...h, text: t(h.text) }
                    })
    }

    return headers.map(h => {
      return { ...h, text: t(h.text) }
    })
  }

  const headers = createHeaders();

  const businessUnits = report => {
    return report.project_business_units.map((pbu, index) => {
      const businessUnitName = pbu.business_unit.name
      const isLongName = businessUnitName.length > 50
      return (
        <OverlayTrigger placement='top' overlay={isLongName ? <Tooltip>{businessUnitName}</Tooltip> : <div/>}>
          <Badge key={index} variant="primary" className='mr-2 mb-1'>
            {isLongName ? businessUnitName.substring(0, 50) + '...' : businessUnitName}
          </Badge>
        </OverlayTrigger>
      );
    });
  };

  const deleteProject = async (projectId) => {
    if(claimProjectReports.find(p => p.id == projectId)?.claim_project_reports?.length > 0) {
       window.alert('The program is associated with project(s)')
    } else {
      if(window.confirm('Are you sure you want to delete this project')){
        await claimProjectReportService.delete(claimGroup.id, projectId);
        window.location.reload();
      }
    }
  }

  const QuestionnairesButton = ({ report }) => {
    return (
      <Link
        className='text-center'
        to={`/${user.account_type}/claim_groups/${report.claim_group_id}/technical_proof/project_reports/${report.id}?key=project_report_tab`}>
        <span className="material-icons text-center">assignment</span>
      </Link>
    );
  }

  const mappingCompletionStatuses = {
    'unstarted': 'not_started',
    'begun_tech_interview_scheduled': 'in_progress_tech_interview_scheduled',
    'begun_tech_interview_completed': 'in_progress_tech_interview_completed',
    'begun_in_manager_qa': 'in_progress_in_manager_qa',
    'begun_manager_qa_completed': 'in_progress_manager_qa_completed',
    'finished': 'completed',
    'completion_status_unstarted': 'not_started',
    'completion_status_in_progress': 'in_progress',
    'completion_status_finished': 'completed',
  }

  const renderExpandButton = (report) => {
    if (report.program_type !== PROJECT_GROUP_RPOGRAM_TYPE_NAME) {
      return ''
    }

    return report.expanded ?
      <ChevronUpButton
        onClick={() => { 
        report.expanded = false
        setClaimProjectReports([...reports])
      }}
      />
      :
      <ChevronDownButton
        onClick={() => { 
          report.expanded = true
          setClaimProjectReports([...reports])
        }}
      />
  }

  const buildRow = (report) => {
    let columns = [...PROJECT_OVERVIEW_TABLE.columns]
    
    if (shouldRemoveSubsidiaryName){
      columns = columns.filter(c => c !== 'subsidiary_company_name')
    }
    
    if (groupProjects) {
      columns.push('toggle_show_projects')
    }

    columns = columns.map(c => {
      switch(c) {
        case 'project_name':
          return pageAccess.canAccessProjectDetailsLink ?
            <Link to={`/${user.account_type}/claim_groups/${report.claim_group_id}/technical_proof/project_reports/${report.id}`}>{report.project_name}</Link>
            :
            report.project_name
        case 'subsidiary_company_name':
          return report?.subsidiary_company.subsidiary_company_name
        case 'assessment_status':
          return t(report.assessment)
        case 'project_code':
          return t(report.project_code)
        case 'internal_project_number':
          return t(report.internal_project_number)
        case 'nature_of_project':
          return t(report.nature_of_project)
        case 'belspo_identification_number':
          return t(report.belspo_identification_number)
        case 'project_eligibility':
          return t(report.project_eligibility)
        case 'category':
          return t(report.category)
        case 'bussiness_units':
          return businessUnits(report)
        case 'total_cost':
          return (<CostFormatter 
                    disabled={true} 
                    object={report} 
                    keyName={'total_cost'}
                  />)
        case 'total_qe':
            return (<CostFormatter 
                      disabled={true} 
                      object={report} 
                      keyName={'total_qe'}
                    />)
        case 'cost':
          return report.total_cost ? t('in_progress') : t('not_started')
        case 'completion_status':
          return t(mappingCompletionStatuses[report.completion_status])
        case 'documents':
          return (
            <Link
              className='text-center'
              to={`/${user.account_type}/claim_groups/${report.claim_group_id}/technical_proof/project_reports/${report.id}?key=documents&model_type=ClaimProjectReport&model_id=${report.id}&folder=`}>
              <span className="material-icons text-center">upload_file</span>
            </Link>
          )
        case 'toggle_show_projects':
          return renderExpandButton(report)
        case 'deleteButton':
          return (
            <DeleteButton
              style={{ marginRight: '8px' }}
              onClick={() => deleteProject(report.id)}
            />
          )
        case '':
          // this is a quick fix for Alexis's demo. otherwise the button should be shown
          return <QuestionnairesButton report={report} />
        default:
          return console.log('Heading doesnt exist')
      }
    });

    return {
      key: report.id,
      columns: columns,
      className: report.isNested ? 'nested-table-row' : '',
    }
  }

  const reportsForRows = []
  claimProjectReports.forEach(cpr => {
    reportsForRows.push(cpr)
    if (cpr.expanded) {
      cpr.claim_project_reports.forEach(nestedCpr => {
        reportsForRows.push({ ...nestedCpr, isNested: true })
      })
    }
  })

  const rows = reportsForRows?.map(report => {
    return buildRow(report)
  });

  const addProject = () => {
    setShowProjectForm(true);
  }; 

  const orderParams = (eventKey, direction) => {
    return `${headers[eventKey].orderBy} ${direction}`
  };

  const sortreports = (eventKey, direction) => {
    setParams({...params, order_by: orderParams(eventKey, direction) });
  };

  const changeReport = (e) => {
    setNewReport({
      ...newReport,
      [e.target.name]: e.target.value
    });
  };

  const setProjectTemplateForNewProject = (report, userSelectedProjectReportTemplate) => {
    // for all categories from the template - remove the id, so we create new ones for the new project
    userSelectedProjectReportTemplate.report_template_categories.forEach((category) => delete category.id);

    // Some fields are in the template, some fields are not - so here we add the missing text fields - Ro1, Ro2, etc.
    const reportTemplateWithAllCategories = TemplateCateogiresForCreatingProject(userSelectedProjectReportTemplate)

    reportTemplateWithAllCategories.report_template_categories.forEach((c) => {
      c.content = c.content || 'default_text';
    });

    setNewReport({
      ...report,
      template_name: userSelectedProjectReportTemplate.name,
      [PROJECT_FIELD_MODEL_NAME + '_attributes']: reportTemplateWithAllCategories?.report_template_categories,
    });

    claimGroup.selectedTemplate = claimGroup.report_template_project_specific?.name ? {} : reportTemplateWithAllCategories;
    setClaimGroup({ ...claimGroup });
  }

  const selectTechnicalTemplate = (e, report) => {
    const userSelectedProjectReportTemplate = claimGroup[TEMPLATE_SELECT_MODEL_NAME].find((t) => t.id === Number(e.target.value));
    if(userSelectedProjectReportTemplate) {
      setProjectTemplateForNewProject(report, userSelectedProjectReportTemplate);
    }else{
      setNewReport({
        ...newReport,
        template_name: ''
      })
    }
  };

  const createReport = async() => {
    try {
      const updatedClaimGroup = await claimGroupService.update({ ...claimGroup, claim_project_reports_attributes: [newReport] });
      setClaimGroup(updatedClaimGroup);
      await getData();
      setShowProjectForm(false);
    } catch (e) {
      dispatch(handleError(e));
    }
  };


  
  const headerButtons = () => {
    if(user.account_type === 'admin' || user.account_type === 'consultant' || user.account_type === 'super_consultant') {
      return (
        <> 
          { SHOW_SEND_QUESTIONNAIRE_BUTTON_ON_PROJECT_OVERVIEW_PAGE &&
            <Button onClick={() => setModalShow(true)} className='mr-2'>
              {t('send_questionnaire')}
            </Button>
          }

          { SHOW_IMPORT_PROJECT_AT_PROJECT_OVERVIEW && (
            <ProjectImportButtonAndModal importProjects={importProjects} importStatus={importStatus}/>
          )}

          <AddButton
            variant="info"
            isImmutableUnderReview={true}
            disabled={false}
            onClick={() => addProject()}
            text={t('add_project')}
          />
      </>
      )
    } else if(user.account_type === 'customer' && SHOULD_SHOW_CREATE_PROJECTS_PAGE_FOR_CUSTOMER) {
      return (
        <>
          <Button className='mr-2' as={Link} to={`/${user.account_type}/claim_groups/${claimGroup.id}/create_project`}>
            {t('add_project')}
          </Button>
        </>
      )
    } else {
      return <></>
    }

  }

  return (
    <div>
      <SetUpQuestionnaireModal
        show={modalShow}
        title={t('questionnaire_set_up')}
        onHide={() => setModalShow(false)}
        submitText={t('send')}
        user={user}
        claimProjectReports={claimProjectReports}
        claimGroup={claimGroup}
      />
      <Row>
        <Col className='text-right'>
          {headerButtons()}
        </Col>
      </Row>

      { SHOW_IMPORT_PROJECT_AT_PROJECT_OVERVIEW && (
        <Row className='my-3'>
          <Col>
            <StaffCostTemplateImportStatus importStatus={importStatus} />
          </Col>
        </Row>
      )}
      


      <Row style={{display: showProjectForm ? 'block' : 'none'}} >
        <Col>
          <div className='table-responsive-responsive'>
            <table className='table table-hover'>
              <thead>
                <tr className='text-primary app-fs-table-header'>
                  <th>{t('project_name')}</th>
                  <th>{t('rnd_project_start_date')}</th>
                  <th>{t('rnd_project_end_date')}</th>
                  { SHOW_PROJECT_TEMPLATE_SELECT &&  
                    <>
                      <th>{t('template_name')}</th>
                      <th>{t('technical_analysis_report')}</th>
                    </>
                  }
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ImmutableUnderReviewFormControl
                      disabled={false}
                      name='project_name'
                      value={newReport.project_name}
                      onChange={changeReport}
                      />
                  </td>
                  <ProjectReportDateColumns
                    datePickerType={datePickerType}
                    disabled={false}
                    report={newReport}
                    handleProjectReportChange={changeReport}
                    handleToaster={handleToaster}
                  />
                  {SHOW_IT_TYPE_SELECT && (
                    <td>
                      <ImmutableUnderReviewFormControl as='select' name='it_type' onChange={changeReport} size='md'>
                        {['default', 'not_it'].map((it_type) => {
                          return (
                            <option key={it_type + 'it_type_key'} value={it_type}>
                              {it_type === 'default' ? 'it' : 'not_it'}
                            </option>
                          );
                        })}
                      </ImmutableUnderReviewFormControl>
                    </td>
                  )}

                  { SHOW_PROJECT_TEMPLATE_SELECT &&
                    <>
                      <td>
                        <ImmutableUnderReviewFormControl as='select' name='selectedTemplate' onChange={(e) => selectTechnicalTemplate(e, newReport)} size='md'>
                          <option value='0'></option>
                          {claimGroup[TEMPLATE_SELECT_MODEL_NAME]?.map((template) => {
                            return (
                              <option key={template.id} value={template.id}>
                                {template.name}
                              </option>
                            );
                          })}
                        </ImmutableUnderReviewFormControl>
                      </td>
                      <td>
                        {newReport.template_name}
                      </td>
                    </>
                  }
                  <td>
                    <SaveButton onClick={createReport} />
                  </td>
                  <td>
                    <DeleteButton
                      style={{ marginRight: '8px' }}
                      onClick={() => setShowProjectForm(false)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </Row>

      <Table
        headers={headers}
        rows={rows}
        onSort={(i, d) => sortreports(i, d)}
        onFilter={(tableHeaders, queryString) => {
          setParams({
            ...params,
            query: queryString
          })
        }}
        textFilterQueryStringParamName='query'
      />
    </div>
  );
}

export default ClaimProjectReportsList
