import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const financialAndPersonnelDocumentsService = {
  all(reportId) {
    return fetch(`/api/claim_project_reports/${reportId}/financial_and_personnel_documents`, request.get);
  },
  create(reportId, formData) {
    return fetch(`/api/claim_project_reports/${reportId}/financial_and_personnel_documents/`, {
      ...request.postFormData,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      },
      body: formData
    });
  },
  update(reportId, financial_and_personnel_document) { 
    return fetch(`/api/claim_project_reports/${reportId}/financial_and_personnel_documents/${financial_and_personnel_document.id}`, {
      ...request.put,
      body: JSON.stringify({ financial_and_personnel_document }),
    });
  },
  delete(reportId, documentId) {
    return fetch(`/api/claim_project_reports/${reportId}/financial_and_personnel_documents/${documentId}`, {
      ...request.delete,
    });
  }
}

export default financialAndPersonnelDocumentsService;
