import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const offerInvoicesService = {
  update(yearId, offer_invoice) {
    return fetch("/api/financial_years/".concat(yearId, "/offer_invoices/").concat(offer_invoice.id), {
      ...request.put,
      body: JSON.stringify({
        offer_invoice
      })
    });
  },
  create(yearId, offer_invoice) {
    return fetch("/api/financial_years/".concat(yearId, "/offer_invoices/"), {
      ...request.post,
      body: JSON.stringify({
        offer_invoice
      })
    });
  },
  delete(yearId, offer_invoice_id) {
    return fetch("/api/financial_years/".concat(yearId, "/offer_invoices/").concat(offer_invoice_id), {
      ...request.delete
    });
  }
};
export default offerInvoicesService;