import { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';

const questionnairesService = {
  useQuestionnaires() {
    const [questionnaires, setQuestionnaires] = useState(null);

    const fetchQuestionnaires = useErrorHandling(useCallback(async () => {
      const response = await questionnairesService.all();
      setQuestionnaires(response.questionnaires);
    }, []));

    useEffect(() => {
      fetchQuestionnaires();
    }, [fetchQuestionnaires]);

    return [questionnaires, setQuestionnaires];
  },
  useQuestionnaire(id) {
    const [questionnaire, setQuestionnaire] = useState({
      id,
      name: '',
      questions: [
        { question_type: 'open_question', body: '', description: '' }
      ]
    });

    const fetchQuestionnaire = useErrorHandling(useCallback(async () => {
      if (id) {
        const questionnaire = await questionnairesService.get(id);
        setQuestionnaire(questionnaire);
      }
    }, [id]));

    useEffect(() => {
      fetchQuestionnaire();
    }, [fetchQuestionnaire]);

    return [questionnaire, setQuestionnaire];
  },
  useSaveQuestionnaire(questionnaire) {
    const history = useHistory();

    return useErrorHandling(useCallback(async (e) => {
      questionnaire.questions_attributes = questionnaire.questions;
      e.preventDefault();
      if (questionnaire.id) {
        await questionnairesService.update(questionnaire.id, questionnaire);
      } else {
        await questionnairesService.create(questionnaire);
      }
      history.goBack();
    }, [questionnaire, history]));
  },
  useDeleteQuestionnaire(setQuestionnaires) {
    return useErrorHandling(useCallback(async (questionnaire, questionnaires) => {
      await questionnairesService.delete(questionnaire.id);
      const updatedQuestionnaires = questionnaires.filter(q => q.id !== questionnaire.id);
      setQuestionnaires([...updatedQuestionnaires]);
    }, [setQuestionnaires]));
  },
  all() {
    return fetch('/api/questionnaires', request.get);
  },
  get(id) {
    return fetch(`/api/questionnaires/${id}`, request.get);
  },
  create(questionnaire) { 
    return fetch('/api/questionnaires', {
      ...request.post,
      body: JSON.stringify({ questionnaire }),
    });
  },
  delete(questionnaireId) {
    return fetch(`/api/questionnaires/${questionnaireId}`, request.delete);
  },
  update(questionnaireId, questionnaire) {
    return fetch(`/api/questionnaires/${questionnaireId}`, {
      ...request.put,
      body: JSON.stringify({ questionnaire }),
    });
  }
}

export default questionnairesService;
