import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const oneTimeLinkUploadsService = {
  update(import_id, linkId, formData) {
    return fetch(`/api/claim_import_cost_template_infos/${import_id}/one_time_link_uploads/${linkId}`, {
      ...request.put,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      },
      body: formData,
    });
  },
  get(import_id, linkId) {
    return fetch(
      `/api/claim_import_cost_template_infos/${import_id}/one_time_link_uploads/${linkId}`, {...request.get }
    );
  }
}

export default oneTimeLinkUploadsService;
