import TemplateForm from '@components/shared/TemplateForm';
import { Loading } from '@rd-web-markets/shared/dist/util';
import claimScheduleService from '@rd-web-markets/shared/dist/services/claim_schedule.service';
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import clientCostTemplateService from '@services/claim_schedule/client_cost_template.service';
import emailAttachmentsService from '@services/email_attachments.service';

const ClaimClientCostTemplateConfigure = ({ claimGroup, claimId, onTemplateSave, onTemplateRemove, onTemplateAttachmentChange }) => {
  const [templates, setTemplates] = useState([]);
  // This variable is used in the array of a useCallback - it gets defined before the asynchronouse useEffect gets fired an sets the claimSchedule.
  // claimSchedule.anything - therefore raises an error.
  const [claimSchedule, setClaimSchedule] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const claimSchedule = await claimScheduleService.get(claimGroup.id, claimGroup.claim_schedule.id);
        setClaimSchedule(claimSchedule);
        const response = await fetch('/api/client_cost_templates');
        setTemplates(response);
      } catch (error) {
        dispatch(handleError(error));
      }
    };
    fetchData();
  }, [claimGroup.claim_schedule.id, claimGroup.id, dispatch]);

  const handleSubmit = async (template) => {
    setLoading(true);
    try {
      if (claimSchedule.active_client_cost_template) {
        await clientCostTemplateService.update(claimSchedule.id, template);
        setClaimSchedule({
          ...claimSchedule,
          client_cost_template: template,
          active_client_cost_template: template
        });
      } else {
        const createdTemplate = await clientCostTemplateService.create(claimSchedule.id, template);
        setClaimSchedule({
          ...claimSchedule,
          client_cost_template: template,
          active_client_cost_template: createdTemplate
        });
      }
      onTemplateSave();
    } catch(error) { 
      dispatch(handleError(error));
    }
    setLoading(false);
  };

  const handleRemoveTemplate = async e => {
    try { 
      await clientCostTemplateService.delete(claimSchedule.id);
      const templates = await fetch('/api/client_cost_templates');
      setTemplates(templates);
      const updatedClaimSchedule = await claimScheduleService.get(claimGroup.id, claimSchedule.id)
      setClaimSchedule(updatedClaimSchedule);
      onTemplateRemove();

    } catch(error) { 
      dispatch(handleError(error));
    }
  };

  const removeAttachment = async (id) => {
    setLoading(true);
    try {
      await emailAttachmentsService.delete('client_cost_templates', claimSchedule.active_client_cost_template.id, id);
      const updatedClaimSchedule = {...claimSchedule}
      updatedClaimSchedule.active_client_cost_template.download_links = updatedClaimSchedule.active_client_cost_template.download_links.filter(link => link.id !== id)
      setClaimSchedule(updatedClaimSchedule);
    } catch(error) {
      dispatch(handleError(error));
    } finally { 
      setLoading(false);
      onTemplateAttachmentChange();
    }
  };

  const addAttachment = useCallback(async (formData) => {
    await emailAttachmentsService.create('client_cost_templates', claimSchedule.active_client_cost_template.id, formData);
    const updatedClaimSchedule = await claimScheduleService.get(claimGroup.id);
    setClaimSchedule({...updatedClaimSchedule});

    setLoading(false);
    onTemplateAttachmentChange();
}, [claimGroup.id, claimSchedule.active_client_cost_template?.id, onTemplateAttachmentChange])

  if (!templates) return <Loading />;

  return (
    <TemplateForm
      addAttachment={addAttachment}
      removeAttachment={removeAttachment}
      handleSubmit={handleSubmit}
      templates={templates}
      templateType='client_cost_template'
      claimSchedule={claimSchedule}
      loading={loading}
      handleRemoveTemplate={handleRemoveTemplate}
      showInModal={true}
      initialSubject={`${claimGroup.company.abbreviated_name || claimGroup.company.name} - R&D Claim - Template`}
    />
  );
}

export default ClaimClientCostTemplateConfigure;
