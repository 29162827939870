const en_translations = {
  project_objectives: 'Projects Objectives',
  company_references: 'Company References',
  intensive_interrogation: 'Intensive Interrogation',
  was_the_customer_questioned_intensively: 'Was the customer questioned intensively until a coherent, concrete description of the goal was possible?',
  summary: 'Summary',
  were_the_problem_motivation_and_the_desired_goal_summarized: 'Were the problem, motivation and the desired goal summarized during the exchange with the customer and was the summary confirmed by the customer?',
  discussion_with_colleagues: 'Discussion with Colleagues',
  was_the_description_discussed_intensively: 'Was the description "Objective of the project" discussed intensively with at least one colleague and "approved" together?',
  motivation: 'Motivation',
  do_i_have_captivating_introduction: 'Do I have a "captivating" introduction: i.e. is the motivation described in a striking and tangible way?',
  objectives_envisaged_results: 'Objectives & Envisaged results',
  are_the_project_goals_recorded: 'Are the project goals recorded as specifcally as possible? As far as possible',
  structure: 'Structure',
  can_i_already_recognize_structure_in_the_goal: 'Can I already recognize a structure/sequence in the goal that can be taken up in the other chapters?',
  security_queries: 'Security Queries',
  are_bad_words_on_target: 'Are bad words on target? (Must be rephrased, otherwise a green doesnt make sense)',
  have_terms_and_formulations_been_mentioned: 'Have terms and formulations been mentioned analogous to other chapters (IMPORTANT: common thread)?',
  have_abbreviations_been_introduced_or_are_technical_terms: 'Have abbreviations been introduced or are technical terms understandable?',
  work_schedule: 'Work Schedule',
  is_there_a_work_plan: 'Is there a work plan, If necessary, send sample work plan.',
  can_clear_rnd_activities: 'Can clear R&D activities for the project be derived from the work plan?',
  can_work_packages_be_divided_into_concept: 'Can work packages be divided into concept, development and test phases?',
  is_there_a_structured_description: 'Is there a structured description?',
  key_point_structure_in_phases: 'Key-point structure in phases/work packages Danger! Test phase always including feedback d. results in development',
  is_the_working_methodology_described: 'Is the working methodology described (sprints, milestones, parallel, iterative...)?',
  can_internal_and_external_activities_be_clearly_defined: 'Can internal and external activities be clearly defined/delineated?',
  have_certain_terms_and_formulations_from_the_goal_been_consistently_incorporated_into_the_work_phases: 'Have certain terms and formulations from the goal been consistently incorporated into the work packages/phases?',
  market_research: 'Market Research',
  have_project_delimitations_been_checked_and_compared: 'Have project delimitations been checked and compared with products/methods/services from competitors?',
  can_qualitative_and_quantitative_delimitation_features_be_listed: 'Can qualitative and quantitative delimitation features be listed?',
  has_the_state_of_research_or_development_in_the_industry_been_briefy_described: 'Has the state of research or development in the industry been briefly described?',
  have_all_new_scientifc_methods_approaches_been_mentioned: 'Have all new scientific/technical methods, approaches or approaches to be used been mentioned?',
  has_it_been_checked_whether_there_is_a_clear_demarcation: 'Has it been checked whether there is a clear demarcation from the market such as therefore there is no comparable product on the market or the company is not aware of any comparable products?',
  have_i_picked_up_the_structure_from_the_goal_here_as_well: 'Have I picked up the structure/sequence from the goal here as well?',
  does_the_customer_have_similar_products_or_is_he_already_advertising: 'Does the customer have similar products or is he already advertising the project on the website? Possibly demarcation necessary',
  routine: 'Routine',
  what_are_routine_activities_for_the_company: 'What are routine activities for the company?',
  how_do_the_new_rnd_activities_difer_from_routine_activities: 'How do the new R&D activities differ from routine activities?',
  what_was_new_to_achieve_the_goal: 'What was new (product/procedure/method/organization) to achieve the goal?',
  has_it_been_checked_whether_no_routine_occurs: 'Has it been checked whether, among other things, no routine occurs in the chapter?',
  are_there_any_bad_words_in_the_description: 'Are there any bad words in the description? e.g. further development, further develop, optimize',
  risks: 'Risks',
  which_technical_risks_have_arisen: 'Which technical risks/uncertainties/challenges have arisen/could arise?',
  has_it_been_carefully_checked_whether_it_is_not_just_hard_work: 'Has it been carefully checked whether it is not just "hard work"?',
  are_risks_quantifiable: 'Are risks quantifiable?',
  conficting_goals: 'Conflicting Goals & Termination Criteria',
  are_there_technical_conficts_of_interest: 'Are there technical conflicts of interest that lead to the project being canceled if they are not achieved?',
  are_the_technical_risks_of_interest_consistent: 'Are the technical risks/conflicts of interest consistent with the objectives and the descriptions of the work?',
  have_terms_and_formulations_been_mentioned_analogous_to_other_chapters: 'Have terms and formulations been mentioned analogous to other chapters (IMPORTANT: common thread)?',
  have_i_picked_up_the_structure_sequence_from_the_goal_here_as_well: 'Have I picked up the structure/sequence from the goal here as well?',
  has_the_common_consistency_from_the_descriptions_been_checked_with_at_least_one_colleague: 'Has the common consistency from the descriptions "Objective of the project", "Description of the work", "Technical risks been checked intensively with at least one colleague and approved together?',
  germany: 'Germany',
  afganistan: 'Afghanistan',
  albania: 'Albania',
  algeria: 'Algeria',
  andorra: 'Andorra',
  angolla: 'Angola',
  antigua: 'Antigua & Deps',
  argentina: 'Argentina',
  armenia: 'Armenia',
  australia: 'Australia',
  austria: 'Austria',
  azerbaijan: 'Azerbaijan',
  bahamas: 'Bahamas',
  bahrain: 'Bahrain',
  bangladesh: 'Bangladesh',
  barbados: 'Barbados',
  belarus: 'Belarus',
  belgium: 'Belgium',
  belize: 'Belize',
  benin: 'Benin',
  bhutan: 'Bhutan',
  bolivia: 'Bolivia',
  bosnia: 'Bosnia Herzegovina',
  botswana: 'Botswana',
  brazil: 'Brazil',
  brunei: 'Brunei',
  bulgaria: 'Bulgaria',
  burkina: 'Burkina',
  burundi: 'Burundi',
  cambodia: 'Cambodia',
  cameroon: 'Cameroon',
  canada: 'Canada',
  cape_verde: 'Cape Verde',
  central_african_rep: 'Central African Rep',
  chad: 'Chad',
  chile: 'Chile',
  china: 'China',
  colombia: 'Colombia',
  comoros: 'Comoros',
  congo: 'Congo',
  congo_democratic: 'Congo {Democratic Rep}',
  cost_rica: 'Costa Rica',
  croatia: 'Croatia',
  cuba: 'Cuba',
  cyprus: 'Cyprus',
  czech_republic: 'Czech Republic',
  denmark: 'Denmark',
  djibouti: 'Djibouti',
  dominica: 'Dominica',
  dominican_republic: 'Dominican Republic',
  east_timor: 'East Timor',
  ecuador: 'Ecuador',
  egypt: 'Egypt',
  el_salvador: 'El Salvador',
  equatorial_guinea: 'Equatorial Guinea',
  eritrea: 'Eritrea',
  estonia: 'Estonia',
  ethiopia: 'Ethiopia',
  fiji: 'Fiji',
  finland: 'Finland',
  france: 'France',
  gabon: 'Gabon',
  gambia: 'Gambia',
  georgia: 'Georgia',
  ghana: 'Ghana',
  greece: 'Greece',
  grenada: 'Grenada',
  guatemala: 'Guatemala',
  guinea: 'Guinea',
  guinea_bissau: 'Guinea-Bissau',
  guyana: 'Guyana',
  haiti: 'Haiti',
  honduras: 'Honduras',
  hungary: 'Hungary',
  iceland: 'Iceland',
  india: 'India',
  indonesia: 'Indonesia',
  iran: 'Iran',
  iraq: 'Iraq',
  ireland: 'Ireland {Republic}',
  israel: 'Israel',
  italy: 'Italy',
  ivory_coast: 'Ivory Coast',
  jamaica: 'Jamaica',
  japan: 'Japan',
  jordan: 'Jordan',
  kazakhstan: 'Kazakhstan',
  kenya: 'Kenya',
  kiribiati: 'Kiribati',
  north_korea: 'Korea North',
  south_korea: 'Korea South',
  kosovo: 'Kosovo',
  kuwait: 'Kuwait',
  kyrgyzstan: 'Kyrgyzstan',
  laos: 'Laos',
  latvia: 'Latvia',
  lebanon: 'Lebanon',
  lesotho: 'Lesotho',
  liberia: 'Liberia',
  libya: 'Libya',
  liechenstein: 'Liechtenstein',
  lithuania: 'Lithuania',
  luxembourg: 'Luxembourg',
  macedonia: 'Macedonia',
  madagascar: 'Madagascar',
  malawi: 'Malawi',
  malaysia: 'Malaysia',
  maldives: 'Maldives',
  mali: 'Mali',
  malta: 'Malta',
  marshall_islands: 'Marshall Islands',
  mauritania: 'Mauritania',
  mauritius: 'Mauritius',
  mexico: 'Mexico',
  micronesia: 'Micronesia',
  moldova: 'Moldova',
  monaco: 'Monaco',
  mongolia: 'Mongolia',
  montenegro: 'Montenegro',
  morocco: 'Morocco',
  mozambique: 'Mozambique',
  myanmar: 'Myanmar, {Burma}',
  namibia: 'Namibia',
  nauru: 'Nauru',
  nepal: 'Nepal',
  netherlands: 'Netherlands',
  new_zealand: 'New Zealand',
  nicaragua: 'Nicaragua',
  niger: 'Niger',
  nigeria: 'Nigeria',
  norway: 'Norway',
  oman: 'Oman',
  pakistan: 'Pakistan',
  palau: 'Palau',
  panama: 'Panama',
  papua_new_guinea: 'Papua New Guinea',
  paraguay: 'Paraguay',
  peru: 'Peru',
  philippines: 'Philippines',
  poland: 'Poland',
  portugal: 'Portugal',
  qatar: 'Qatar',
  romania: 'Romania',
  russian_federation: 'Russian Federation',
  rwanda: 'Rwanda',
  st_kitts_nevis: 'St Kitts & Nevis',
  st_lucia: 'St Lucia',
  saint_vincent: 'Saint Vincent & the Grenadines',
  samoa: 'Samoa',
  san_marino: 'San Marino',
  sao_tome: 'Sao Tome & Principe',
  saudi_arabia: 'Saudi Arabia',
  senegal: 'Senegal',
  serbia: 'Serbia',
  seychelles: 'Seychelles',
  sierra_leone: 'Sierra Leone',
  singapore: 'Singapore',
  slovakia: 'Slovakia',
  slovenia: 'Slovenia',
  solomon_islands: 'Solomon Islands',
  somalia: 'Somalia',
  south_africa: 'South Africa',
  south_sudan: 'South Sudan',
  spain: 'Spain',
  sri_lanka: 'Sri Lanka',
  sudan: 'Sudan',
  suriname: 'Suriname',
  swaziland: 'Swaziland',
  sweden: 'Sweden',
  switzerland: 'Switzerland',
  syria: 'Syria',
  taiwan: 'Taiwan',
  tajikistan: 'Tajikistan',
  tanzania: 'Tanzania',
  thailand: 'Thailand',
  togo: 'Togo',
  thonga: 'Tonga',
  trinidad_and_tobago: 'Trinidad & Tobago',
  tunisia: 'Tunisia',
  turkey: 'Turkey',
  turkmenistan: 'Turkmenistan',
  tuvalu: 'Tuvalu',
  uganda: 'Uganda',
  ukraine: 'Ukraine',
  united_arab_emirates: 'United Arab Emirates',
  united_kingdom: 'United Kingdom',
  united_states: 'United States',
  uruguay: 'Uruguay',
  uzbekistan: 'Uzbekistan',
  vanuatu: 'Vanuatu',
  vatican_city: 'Vatican City',
  venezuela: 'Venezuela',
  vietnam: 'Vietnam',
  yemen: 'Yemen',
  zambia: 'Zambia',
  zimbabwe: 'Zimbabwe',
  baden_wuerttemberg: 'Baden-Wurttemberg',
  bayern: 'Bavaria',
  berlin: 'Berlin',
  brandenburg: 'Brandenburg',
  bremen: 'Bremen',
  hamuburg: 'Hamburg',
  hesse: 'Hesse',
  mecklenburg_vorpommern: 'Mecklenburg Western Pomerania',
  lower_saxony: 'Lower Saxony',
  north_rhine_westphalia: 'Northrhine-Westphalia',
  rheinland_pfalz: 'Rhineland Palatinate',
  saarland: 'Saarland',
  saxony: 'Saxony',
  saxony_anhalt: 'Saxony-Anhalt',
  schleswig_holstein: 'Schleswig Holstein',
  thuringia: 'Thuringia',

  //elsterdocx
  transmission_protocol: 'Transmission Protocol',

  //table of content elster docx
  tk_ginfo_company_1: '1. General information about the company',
  tk_iorado_projects_2: '2. Information on research and development projects',
  tk_freq_4: '4. Further requirements under European state aid law',
  tk_dt_as_5: '5. Determination of the assessment basis and the requested research allowance',
  tk_final_dec_6: 'Final Declaration',
  tk_participation_7: '7. Participation in the preparation of this application',
  tk_power_of_attorney_8: '8. Power of attorney',
  tk_info_totwage_enterprise_finy: 'Information on total wage costs in the enterprise during the financial year',
  tk_information_wage_costs_eng_res: 'Information on wage costs of employees engaged in research and development projects in the enterprise as a whole in the financial',

  application_for_research_allowance: 'Application for research allowance',
  application_for_research_allowance_body: 'Please note that you can only apply for a research allowance for each financial year. This also applies if you have carried out several eligible research and development projects in one financial year.',

  iorado_projects_subtitle: 'I apply for the research allowance for the following research and development projects:',
  iorado_only_for_in_house_research_and_development_projects: 'Only for in-house research and development projects',
  iorado_wages_for_own_employees: 'Wages for own employees in the respective financial year, insofar as they have carried out research and development activities in this beneficiary research and development project:',
  iorado_own_cont_of_a_sole_proprietor: 'Own contributions of a sole proprietor',
  iorado_own_cont_by_shareholders: 'Own contributions by shareholders',
  iorado_only_for_contract_research: 'Only for contract research',
  iorado_contractors_data: 'Contractor\'s data',
  only_when_received_or_applying_for_other_subsidies: 'Only when receiving or applying for other subsidies',
  iorado_determination_b4_2020: 'Determination of the assessment basis for eligible expenses incurred before 01.07.2020',
  tk_deminimis_section3_3_subtitle: 'Only necessary if personal contributions by a sole proprietor or by partners of a co-entrepreneurship to the research and development projects listed in this application are declared.',
dtas_determination_of_the_tax_base: 'Determination of the tax base',
dtas_determination_of_the_tax_base_subtitle1: '1.1 Determination of the assessment basis for eligible expenses incurred after 01.01.2020 and before 01.07.2020',

  dtas_table2_title: 'Eligible expenses from in-house research and development projects: Own contributions by a sole proprietor or by partners of a co-entrepreneurship',
  dtas_table3_title: 'Eligible expenditure from contract research',
  dtas_table4_title: 'Subtotal of eligible expenses incurred after 01.01.2020 and before 01.07.2020:',
  dtas_table5_title: '1.2 Determination of the assessment basis for eligible expenses incurred after 30.06.2020',
  dtas_table5_subtitle: 'Eligible expenditure from in-house research and development projects: Wages',
  dtas_table6_title: 'Eligible expenses from in-house research and development projects: Own contributions by a sole proprietor or by partners of a co-entrepreneurship',
  dtas_table8_title: 'Subtotal of eligible expenses incurred after 30.06.2020:',
  dtas_table9_title: '1.3 Determination of the eligible assessment basis for the respective financial year',
  dtas_table10_title: '1. Determination of the research allowance applied for',
  dtas_table10_subtitle: 'Note: If own contributions are declared for shareholders of a co-entrepreneurship, the assessment basis and the requested research allowance will be corrected if necessary as part of the determination of the research allowance.',
  dtas_table11_title: '1. De minimis examination for eligible expenses from own contributions',
  dtas_table11_subtitle_1: 'The research allowance, which is attributable to own contributions, is granted as de minimis aid within the meaning of the de minimis Regulation (Commission No 1407/2013 of 18 December 2013 on the application of Articles 107 and 108 of the Treaty on the Functioning of the European Union to de minimis aid). Note: If own contributions are declared for partners of a co-entrepreneurship, the de minimis examination will be corrected if necessary as part of the determination of the research allowance.',
  dtas_table11_subtitle_2: '3.1 Determination of the aid value when applying a maximum assessment basis amount',
  dtas_table12_title: '3.2 Determination of the aid value without limitation to a maximum basis of assessment',



  business_address: 'Business Address',
  address_at_home_abroad: 'Address at Home/Abroad',
  legal_representative: 'Legal Representative',
  information_on_tax_exemption: 'Information on tax exemption',
  information_on_the_distribution: 'Information on the distribution of the maximum eligible tax base for affiliated companies',
  information_on_affiliated_companies: 'Information on affiliated companies',
  gci_please_note_that_only: 'Please note that only affiliated companies that have also submitted or will apply for a research allowance are to be registered.',
  information_on_total_wage_cost_in_the_enterprise_during_the_financial_year: 'Information on total wage costs in the enterprise during the financial year',
  information_on_wage_costs_of_employees_engaged_in_research: 'Information on wage costs of employees engaged in research and development projects in the enterprise as a whole in the financial',
  additional_information_for_partnership_subtitle: 'Authorised recipient pursuant to Section 122 (6) of the German Fiscal Code for the decision on the separate and uniform determination of shares in the determined research allowance',
  details_of_the_parties_involved_in_the_assessment: 'Details of the parties involved in the assessment at the end of the financial year',
  information_on_breakdown_11: 'Information on the breakdown of the research allowance by subscribed or paid-in capital or by fractions',


  research_allowance_project_profile: 'Research Allowance: Transfer Protocol',
  company_name: 'Research Allowance: Transfer Protocol',
  location: 'Location',
  legal_form: 'Legal Form',
  industry: 'Industry',
  tax_group: 'Tax Group',
  street: 'street',
  house_number: 'House Number',
  postal_code: 'postal code',
  place: 'Place',
  country_in_case_of_foreign_countries: 'Country in Case of Foreign Countries',
  salutation: 'Salutation',
  name: 'Name',
  forename: 'Forename',
  gci_4_the_company_exempt: 'The company is exempt from taxation under Section 5 of the Corporate Income Tax Act',
  gci_4_the_research_and_dev: 'The research and development projects described below were carried out within the framework of an economic business operation or a non-tax-exempt business of a commercial nature by legal entities under public law',
  gci_5_it_is_an_affiliated: 'It is an affiliated company within the meaning of Section 3 (6) FZulG. The circumstances at the end of the financial year are decisive',
  gci_5_has_a_company_affiliated: 'Has a company affiliated with you applied for a research allowance for financial years that end in the same calendar year in which your financial year ends, or will an affiliated company apply for a research allowance?',
  gci_5_will_the_max_eligible_ex: 'Will the maximum eligible assessment basis for affiliated companies of €4 million be exceeded?',
  gci_5_maximum_eligible_ass: 'Maximum eligible assessment basis to be taken into account for this application for research allowance.',
  gci_6_tax_number: 'Tax number of the affiliated company',
  gci_6_name_of_the_aff: 'Name of the affiliated company',
  gci_6_if_available: 'If available: Date of application for research allowance If applicable, date of the decision on the research allowance',
  gci_7_total_number_of: 'Total number of employees employed by the company for whom payroll tax is deducted in the financial year (in terms of full-time equivalents)',
  gci_7_number_of_employees: 'Number of employees employed by the company for whom no wage tax is deducted due to a DTA regulation, in total in the financial year (according to full-time equivalents)',
  gci_7_gross_wages_granted_deduction: 'Gross wages granted directly by the employer to employees and from which the wage tax deduction was made in accordance with Section 38 (1) of the Income Tax Act in total in the financial year',
  gci_7_gross_wages_granted_pursuant: 'Gross wages granted directly by the employer to employees and for which wage tax deduction pursuant to Section 38 (1) EStG is not made due to a DTA regulation, in total in the financial year',
  gci_7_expenditure: 'Expenditure for securing the future of employees in accordance with § 3 no. 62 EStG in total in the financial',
  gci_8_total_number: 'Total number of employees employed in the enterprise engaged in research and development projects for whom income tax is deducted in the financial year (by full-time equivalents)',
  gci_8_number_of_employees: 'Number of employees employed in the company with research and development projects for whom no wage tax is deducted due to a DTA regulation, in total in the financial year (according to full-time equivalents)',
  gci_8_gross_wages_deducted: 'Gross wages granted directly by the employer to employees entrusted with research and development projects and from which wage tax was deducted in accordance with Section 38 (1) of the Income Tax Act in total in the financial year',
  gci_8_gross_wages_pursuant: 'Gross wages granted directly by the employer to employees entrusted with research and development projects and for which wage tax deduction pursuant to Section 38 (1) of the Income Tax Act has not been made due to a DTA regulation, in total in the financial year',
  gci_8_expenditure: 'Expenditure for securing the future of employees entrusted with research and development projects in accordance with Section 3 No. 62 of the Income Tax Act in the financial year as a whole',
  name_line_1: 'Name line 1',
  name_line_2: 'Name line 2',
  gci_10_number: 'Number of the party involved in the determination',
  postcode: 'Postal code',
  tin: 'Tin',
  identificaiton: 'Identificaiton',
  type_of_participant: 'Type of Participant',
  gci_11_capital: 'Capital at the end of the financial year',
  this_document_is_the_property_of_ayming: 'This Document is property of Ayming',


  iorado_p_1_project_id: 'Project ID of the research and development project from the notification of the certification body for the research allowance in accordance with § 6 FZulG',
  iorado_p_1_tax_number_of: 'Tax number of the company in which the research and development project is carried out.',
  iorado_p_1_name_of_researcher: 'Name of the research and development project',
  iorado_p_1_art: 'Art',
  iorado_p_1_cooperation_projects: 'Cooperation projects',
  iorado_p_1_start_of_work: 'Start of work / Date of placing the order',
  iorado_p_1_the_decision_of_the: 'The decision of the certification body for the research allowance pursuant to § 6 FZulG for this research and development project has been submitted to the tax office.',
  iorado_p_1_the_information_declared: 'The information declared in the course of the certification procedure and on which the decision pursuant to § 6 FZulG was based has not changed.',
  iorado_p_1_further_has: 'Further funding has been/will be applied for and/or granted for this research and development project.',

  iorado_p_2_the_research_and_development: 'The research and development project is not being carried out on behalf of another company',

  iorado_p_3_1: 'Number of employees engaged in research and development activities in research and development projects (by full-time equivalents)',
  iorado_p_3_2: 'corresponds to a number according to heads',
  iorado_p_3_3: 'Proportion of gross wages granted directly to employees by the employer and from which the wage tax deduction was made in accordance with Section 38 (1) of the Income Tax Act, which is attributable to the research and development activities of the research and development project in the financial year',
  iorado_p_3_4: 'of which share of gross wages to shareholders of a corporation (included in the line before)',
  iorado_p_3_5: 'of which received by employees before 01.07.2020 or after 30.06.2026',
  iorado_p_3_6: 'of which after 30.06.2020 and before 01.07.2026 received by the employees',
  iorado_p_3_7: 'Proportion of gross wages granted directly by the employer to employees and from which the wage tax deduction pursuant to Section 38 (1) of the Income Tax Act has not been made on the basis of a DTA regulation, which is attributable to the research and development activities of the research and development project in the financial year',
  iorado_p_3_8: 'of which received by employees before 01.07.2020 or after 30.06.2026 ',
  iorado_p_3_9: 'of which after 30.06.2020 and before 01.07.2026 received by the employees ',
  iorado_p_3_10: 'Pro-rata expenditure for securing the future of employees in accordance with Section 3 No. 62 of the Income Tax Act that is attributable to research and development activities of the research and development project',
  iorado_p_3_11: 'of which paid before 01.07.2020 or after 30.06.2026',
  iorado_p_3_12: 'of which paid after 30.06.2020 and before 01.07.2026',

  iorado_p_4_1: 'Own research and development activities on the research and development project were carried out',
  iorado_p_4_2: 'Own working hours for research and development activities in this research and development project',
  iorado_p_4_3: 'of which paid before 01.07.2020 or after 30.06.2025',
  iorado_p_4_4: 'of which paid after 30.06.2020 and before 01.07.2026',

  iorado_p_5_1: 'Name of the shareholder',
  iorado_p_5_2: 'A contractual agreement that is effective under civil law exists',
  iorado_p_5_3: 'Total remuneration for shareholders',
  iorado_p_5_4: 'Remuneration for activities for research and development activities in this research and development project',
  iorado_p_5_5: 'Working hours of the shareholder for research and development activities in this research and development project',
  iorado_p_5_6: 'of which paid before 01.07.2020 or after 30.06.2025',
  iorado_p_5_7: 'of which paid after 30.06.2020 and before 01.07.2026',


  iorado_p_6_1: 'The contract was not placed as a subcontract from a separate commission',
  iorado_p_6_2: 'Total remuneration paid to the contractor during the financial year',
  iorado_p_6_3: 'of which paid before 01.07.2020 or after 30.06.2026',
  iorado_p_6_4: 'of which paid after 30.06.2020 and before 01.07.2026',
  iorado_p_6_5: 'ShapeFurther funding has been/will be applied for and/or granted for this research and development project.',

  iorado_p_7_1: 'Consent is granted to the transfer of the information on this research and development project from the decision of the Certification Office for the Research Allowance in accordance with § 6 FZulG.',
  iorado_p_7_2: 'Name of the company ',
  state: 'state',

  iorado_p_8_1: 'Date of the approval notice / acceptance',

  iorado_p_9_1: 'The research and development project is carried out in cooperation with affiliated companies.',
  iorado_p_9_2: 'The percentage of the research and development project carried out in cooperation with affiliated companies (in percentage).',
  iorado_p_9_3: 'The research and development project is carried out in cooperation with tax-exempt research institutions.',
  iorado_p_9_4: 'The percentage of the research and development project carried out in cooperation with tax-exempt research institutions (in percentage).',
  iorado_p_9_5: 'The research and development project is carried out in cooperation with other taxable companies.',
  iorado_p_9_6: 'The percentage of the research and development project carried out in cooperation with other taxable companies (in percentage).',

  iorado_p_10_1: 'Sum of wages for own employees, insofar as they have carried out research and development activities in this beneficiary research and development project:',
  iorado_p_10_2: 'Less expenses for which another subsidy is/was claimed',
  iorado_p_10_3: 'Eligible expenses from wages for own employees, insofar as they have carried out research and development activities in this eligible research and development project (before 01.07.2020):',

  iorado_p_11_1: 'Sum of wages for own employees, insofar as they have carried out research and development activities in this beneficiary research and development project:',
  iorado_p_11_2: 'Less expenses for which another subsidy is/was claimed',
  iorado_p_11_3: 'Eligible expenses from wages for own employees, insofar as they have carried out research and development activities in this beneficiary research and development project (after 30.06.2020):',

  dmver_1_1: 'Im laufenden Kalenderjahr sowie in den zwei vorangegangenen Kalenderjahren wurden Beihilfen im Sinne der Verordnungen (EU) Nummer 1407/2013 der Kommission vom 18. Dezember 2013 über die Anwendung der Artikel 107 und 108 des Vertrags über die Arbeitsweise der Europäischen Union auf De-minimis-Beihilfen (De-minimis-Verordnung) oder nach der Verordnung (EU) Nummer 360/2012 (sogenannten DAWI-de-minimis-Verordnung) bezogen.',

  dmver_2_1: 'Date of the approval notice / acceptance',
  dmver_2_2: 'Aid providers',
  dmver_2_3: 'Aid value',

  dmver_3_1: 'Date of the approval notice / acceptance',
  dmver_3_2: 'Aid providers',
  dmver_3_3: 'Aid value',

  fr_1_1: 'It is a firm in difficulty within the meaning of Article 1(4)(c) and Article 2(18) of Regulation (EU) No 651/2014 of the European Commission of 17 June 2014 declaring certain categories of aid compatible with the internal market pursuant to Articles 107 and 108 of the Treaty on the Functioning of the European Union (GBER) (OJ 2014 L 18, p. OJ L 187, 26.6.2014, page 1).',
  fr_1_2: 'There is no obligation to repay aid under a previous decision of the European Commission declaring aid inadmissible and incompatible with the internal market, which has not been complied with.',
  fr_1_3: 'ShapeThe beneficiary establishment is a small or medium-sized enterprise as defined in Annex I to the Regulation	Shape(EU) Commission No 651/2014 of 17 June 2014 declaring certain categories of aid compatible with the internal market pursuant to Articles 107 and 108 of the Treaty on the Functioning of the European Union (GBER) OJ L 187, 26.6.2014, page 1). ',

  dos_1_1: 'Sum of eligible expenses from wages for own employees, insofar as they have carried out research and development activities in subsidised research and development projects:',

  dos_2_1: 'Declared working hours of a sole proprietor:',
  dos_2_2: 'of which maximum eligible hours: (Note X/12 x 2,080 hours; where X is the number of months of the financial year between 02.01.2020 and 30.06.2020 and 2,080 hours are the maximum working hours permitted in a financial year, determined from 40 weekly working hours x 52 weeks)',
  dos_2_3: 'Declared working hours of partners of a co-entrepreneurship:',
  dos_2_4: 'of which maximum eligible hours: (Note X/12 x 2,080 hours; where X is the number of months of the financial year between 02.01.2020 and 30.06.2020 and 2,080 hours are the maximum working hours permitted in a financial year, determined from 40 weekly working hours x 52 weeks)',
  dos_2_5: 'Total eligible expenses from own contributions: (Note: maximum eligible hours x 40 euros)',

  dos_3_1: 'Total remuneration for contract research',
  dos_3_2: 'Davon 60 Prozent Publisher:',

  dos_4_1: 'Subtotal of eligible expenses incurred after 01.01.2020 and before 01.07.2020:',

  dos_5_1: 'Sum of eligible expenses from wages for own employees, insofar as they have carried out research and development activities in subsidised research and development projects:',

  dos_6_1: 'Declared working hours of a sole proprietor:',
  dos_6_2: 'of which maximum eligible hours: (Note X/12 x 2,080 hours; where X is the number of months of the financial year from 01.07.2020 and 2,080 hours are the maximum working hours permitted in a financial year, determined from 40 weekly working hours x 52 weeks)',
  dos_6_3: 'Declared working hours of partners of a co-entrepreneurship:',
  dos_6_4: 'of which maximum eligible hours: (Note X/12 x 2,080 hours; where X is the number of months of the financial year from 01.07.2020 and 2,080 hours are the maximum working hours permitted in a financial year, determined from 40 weekly working hours x 52 weeks)',
  dos_6_5: 'Total eligible expenses from own contributions: (Note: maximum eligible hours x 40 euros)',

  dos_7_1: 'Total remuneration for contract research',
  dos_7_2: 'Davon 60 Prozent Publisher:',

  dos_8_1: 'Subtotal of eligible expenses incurred after 30.06.2020:',

  dos_9_1: 'Eligible expenses incurred after 01.01.2020 and before 01.07.2020 (maximum 2,000,000 euros):',
  dos_9_2: 'Total eligible expenses incurred after 01.01.2020 and before 01.07.2020 (maximum 2,000,000 euros) and eligible expenses incurred after 30.06.2020:',
  dos_9_3: 'Eligible assessment basis (total of eligible expenses in the financial year, maximum 4,000,000 euros):',
  dos_9_4: 'Maximum eligible assessment basis for affiliated companies',

  dos_10_1: 'Requested research allowance (subject to the examination of the eligible own contributions by your tax office):',

  dos_11_1: 'Determination of the subsidised own contribution due to the limitation of the assessment basis in the 1st half of the year: Assessment of the maximum assessment basis amount until 30.06.2020, but eligible assessment basis below EUR 4,000,000',
  dos_11_2: 'Determination of the subsidised own contribution due to the limitation of the assessment basis in the 2nd half of the year: Assessment of the maximum assessment basis amount of EUR 4,000,000 for the förderfähigen Bemessungsgrundlage:',
  dos_11_3: 'The research allowance applied for does not include de minimis aid. Since your eligible wage expenses and/or the eligible expenses of contract research already reach the maximum assessment basis limit, the requested research allowance is not attributable to own contributions.',
  dos_12_1: 'Sum of eligible own contributions',
  dos_12_2: 'The part of the research allowance attributable to the own contributions amounts to: (25 percent of the total of the eligible own contributions):',
  dos_12_3: 'Aid value of de minimis aid already received (in the calendar year and the two previous years):',
  dos_12_4: 'Aid value of de minimis aid already received and/or SGEI de minimis aid (in the calendar year and the two previous years):',
  dos_12_5: 'The research allowance is applied for taking into account the de minimis regulation in the amount of:',

  final_declaration_1: 'I declare that I have truthfully provided the above information to the best of my knowledge and belief and can substantiate it with appropriate documents. I am aware that the indication of false facts as well as the failure to report can result in criminal consequences (§§ 370, 153 AO). I further declare that I recognise the application of Commission Regulation (EC) No. 1407/2013 of 18 December 2013 as the legal basis and that I am aware that the above information is a fact relevant for tax purposes within the meaning of § 370 AO. In addition, I am aware that facts relevant for tax purposes include, in particular, any facts that are concealed by fictitious transactions and fictitious acts, as well as legal transactions or acts that abuse the structuring possibilities in connection with the research allowance claimed. This shall also apply to subsequent amendments to agreements or legal acts made with the aim of determining the date of commencement of the investment project or the completion of the investment to a period that results in a research allowance in order to claim a research allowance or a higher research allowance. I will notify the tax office immediately if information in this application changes subsequently that has an impact on the determination of the research allowance.',
  final_declaration_2: 'I confirm that I have taken note of the information on the protection and publication of the personal data submitted in the context of the application for the research allowance and consent to its publication.',

  participation_1_1: 'The application was prepared with the assistance of a member of the tax advisory professions within the meaning of §§ 3 and 4 StBerG.',
  ort: 'ort',
  phone_number: 'Phone number',
  adiitional_information: 'Additional Information',
  mandator_number: 'Mandator Number',
  editor_identifier: 'Editor Identifier',
  power_of_attorney_1_1: 'The research allowance notice should be sent to a different recipient.',

}

export default en_translations
